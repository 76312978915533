<template>
  <ion-page>
    <ion-content>
      <div class="agente-tab" key="tab-conteo" v-if="datosConvocatoriaActual">
        <div class="mobileShow">
          <div class="banner nivel1">{{ centroJunta }}</div>
          <div class="banner nivel2">{{ municipio }}</div>
        </div>

        <div class="conteoRapidoNoCruzado" v-if="!votoCruzadoActual.visible">
          <div class="banner nivel1 hdr">CONTEO RÁPIDO</div>

          <div class="banner nivel2">Escoja tipo de elección</div>

          <ion-select interface="popover" v-model="convocatoria">
            <ion-select-option
              v-for="(caption, id) in convocatorias"
              :key="id"
              :value="id"
            >
              {{ caption }}
            </ion-select-option>
          </ion-select>

          <div class="banner nivel2" v-if="cerrado">
            El conteo rápido para esta convocatoria está cerrado.
          </div>

          <div class="banner nivel2" v-if="!cerrado && tieneVotoCruzado">
            Marque aquí los votos a bandera. Si hay un voto preferencial o cruzado, pulse sobre "Preferen." para registrar el voto.
          </div>
          

          <ion-grid>
            <ion-row>
              <ion-col
                size="6"
                size-lg="2"
                size-s="4"
                size-md="3"
                v-for="partido in partidos"
                :key="partido.id"
              >
                <div class="partido-outer">
                  <div
                    @click="addVoto(partido.id)"
                    :class="[
                      'partido-top',
                      partido.es_oscuro ? 'partido-oscuro' : 'partido-claro',
                      votoActivo[partido.id] ? 'partido-activo' : '',
                    ]"
                    :style="{ backgroundColor: '#' + partido.color }"
                  >
                    <div class="partido-siglas">{{ partido.siglas }}</div>
                    <div class="partido-votos">{{ estado[partido.id] }}</div>
                  </div>

                  <div
                    class="partido-bottom"
                    v-if="!cerrado && partido.id != 50"
                  >
                    <ion-button
                      expand="block"
                      size="small"
                      @click="delVoto(partido.id)"
                      color="secondary"
                      >Corregir</ion-button
                    >
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button expand="block" v-if="!cerrado" @click="cerrarCR">
          <!-- eslint-disable vue/no-deprecated-slot-attribute -->
          <ion-icon slot="start" :icon="lockClosedOutline"></ion-icon>

            Cerrar conteo rápido
          </ion-button>
          <ion-button expand="block" color="secondary" v-if="tieneVotoCruzado" @click="detalleVF">
            <!-- eslint-disable vue/no-deprecated-slot-attribute -->
            <ion-icon slot="start" :icon="checkmarkCircleOutline"></ion-icon>
            Detalle de voto preferencial
          </ion-button>
        </div>

        <div class="conteoRapidoCruzado" v-if="votoCruzadoActual.visible">
          <div class="banner nivel1 hdr">REGISTRAR VOTO PREFERENCIAL</div>

          <div class="banner nivel2" v-if="!cerrado && tieneVotoCruzado">
            Marque en la pestaña {{ nombrePestanyaNI }} los candidatos de {{ nombreNuevasIdeas }} que reciben marcas, y en OTROS PARTIDOS el número de marcas que reciben el resto de partidos.
          </div>

          <ion-segment scrollable v-model="votoCruzadoActual.pestanya">
            <ion-segment-button key="k1" :value="1">
              {{ nombrePestanyaNI }} ({{ votoCruzadoActual.numCandidatosNI }})
            </ion-segment-button>
            <ion-segment-button key="k2" :value="2">
              OTROS PARTIDOS ({{ votoCruzadoActual.numOtrosPartidos }})
            </ion-segment-button>
          </ion-segment>

          <transition name="fade" mode="out-in">
            <div v-if="votoCruzadoActual.pestanya!=2" key="k1">
              <ion-grid>
                <ion-row>
                  <ion-col
                    size="6"
                    size-lg="2"
                    size-s="4"
                    size-md="3"
                    v-for="candidato in votoCruzadoActual.candidatosNI"
                    :key="candidato.idx"
                  >
                    <div class="partido-outer">
                      <div
                        @click="cruzadoMarcarCandidatoNI(candidato.idx)"
                        :class="[
                          'partido-top', 'partido-claro', candidato.voted ? 'candidato-voto' : 'candidato-no-voto'
                        ]"
                      >
                        <div class="partido-siglas">Cand. {{ siglasNuevasIdeas }} </div>
                        <div class="partido-votos">
                          {{ candidato.idx }}
                        </div>
                      </div>

                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
            <div  v-else key="k2">


          <ion-grid>
            <ion-row>
              <ion-col
                size="6"
                size-lg="2"
                size-s="4"
                size-md="3"
                v-for="partido in votoCruzadoActual.otrosPartidos"
                :key="partido.id"
              >
                <div class="partido-outer">
                  <div
                    @click="cruzadoAddVoto(partido.id)"
                    :class="[
                      'partido-top',
                      partido.es_oscuro ? 'partido-oscuro' : 'partido-claro',
                    ]"
                    :style="{ backgroundColor: '#' + partido.color }"
                  >
                    <div class="partido-siglas">{{ partido.siglas }}</div>
                    <div class="partido-votos">{{ votoCruzadoActual.votosOtrosPartidos[partido.id] }}</div>
                  </div>

                  <div
                    class="partido-bottom"
                  >
                    <ion-button
                      expand="block"
                      size="small"
                      @click="cruzadoDelVoto(partido.id)"
                      color="secondary"
                      >Corregir</ion-button
                    >
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
            </div>
          </transition>

          <ion-button expand="block" @click="cruzadoEnviar">
            
          <!-- eslint-disable vue/no-deprecated-slot-attribute -->
          <ion-icon slot="start" :icon="checkmarkCircleOutline"></ion-icon>

            REGISTRAR VOTO PREFERENCIAL</ion-button>
          <ion-button expand="block" color="secondary" @click="cruzadoDescartar">
          <!-- eslint-disable vue/no-deprecated-slot-attribute -->
          <ion-icon slot="start" :icon="closeCircleOutline"></ion-icon>

            
            DESCARTAR VOTO PREFERENCIAL</ion-button>

        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import store from "@/modules/adaptedStorage";

import { defineComponent } from "vue";
import { 
  lockClosedOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
 } from 'ionicons/icons';

import {
  IonPage,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  alertController,
} from "@ionic/vue";
import cyanRequest from "@/modules/cyanRequest";
import { municipios } from "@/modules/cyanRegions";

export default defineComponent({
  name: "AgenteConteo",
  components: {
    IonPage,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonIcon,
    IonRow,
    IonContent,
    IonCol,
    IonButton,
    IonSegment,
    IonSegmentButton,
  },

  setup() {
    return {
      lockClosedOutline,
      checkmarkCircleOutline,
      closeCircleOutline,
    }
  },
  data() {
    return {
      convocatoria: "0",
      votoActivo: {},
      votoCruzadoActual: {
        candidatosNI: {} as any,
        totalCandidatosNI: 0,
        pestanya: 1,
        otrosPartidos: [] as any,
        votosOtrosPartidos: {} as any,
        numCandidatosNI: 0,
        numOtrosPartidos: 0,
        visible: false,
      },
    };
  },
  computed: {
    junta(): any {
      return (this as any).$route.params.junta || 0;
    },
    bootstrap(): any {
      return store.state.bootstrap[this.junta] || {};
    },
    datosConvocatorias() {
      return (this as any).bootstrap.datos_convocatorias || {}
    },
    datosConvocatoriaActual() {
      return (this as any).datosConvocatorias[(this as any).convocatoria] || {}
    },

    convocatorias(): any {
      return this.bootstrap.convocatorias || { "0": "Error" };
    },
    municipio(): string {
      if (this.seed in municipios) {
        const m = municipios[(this as any).seed];
        return m.nombre + ", " + m.nombrePadre;
      }
      return "";
    },

    centroJunta(): any {
      const b = this.bootstrap;
      if (!b || !b.centro || !b.junta) return "";
      return "JRV " + b.junta.nombre + ", " + b.centro.nombre;
    },

    baseUrl(): string {
      const p = (this as any).$route.params;

      return "/agente/" + p.ambito + "/" + p.junta + "/";
    },
    seed(): any {
      return (this as any).$route.params.ambito || "SV";
    },
    mapaPartidos(): any {
      if (
        !this.datosConvocatoriaActual ||
        !this.datosConvocatoriaActual.mapa_partidos
      )
      return {};

      return this.datosConvocatoriaActual.mapa_partidos;

    },
    partidos(): any {
      if (
        !this.datosConvocatoriaActual ||
        !this.datosConvocatoriaActual.orden_partidos
      )
        return [];

      const r = [];
      const dca = this.datosConvocatoriaActual;

      for (const x of dca.orden_partidos) {
        if (dca.mapa_partidos[x]) r.push(dca.mapa_partidos[x]);
      }

      return r;
    },
    tieneVotoCruzado(): boolean {
      return !! (this as any).datosConvocatoriaActual.tiene_voto_fraccionado;
    },
    representantesVotoCruzado(): number {
      return (this as any).datosConvocatoriaActual.numero_representantes ?? 0;
    },

    idNuevasIdeas(): string {
      return (this as any).datosConvocatoriaActual.id_ni ?? 0;
    },

    nombreNuevasIdeas(): string {
      const _t = this as any;
      const x = _t.mapaPartidos[_t.idNuevasIdeas];
      if (x && x.nombre) return x.nombre;
      return '???';
    },
    siglasNuevasIdeas(): string {
      const _t = this as any;
      const x = _t.mapaPartidos[_t.idNuevasIdeas];
      if (x && x.siglas) return x.siglas;
      return '???';
    },

    nombrePestanyaNI(): string {
      const _t = this as any;
      const x = _t.mapaPartidos[_t.idNuevasIdeas];
      if (x.es_alianza) return 'COALICIÓN ' + (this as any).siglasNuevasIdeas;
      return _t.nombreNuevasIdeas.toUpperCase();
    },
    

    estadoGlobal(): any {
      if (
        !store.state.conteo[this.junta] ||
        !store.state.conteo[this.junta][this.convocatoria]
      )
        return {};

      return store.state.conteo[this.junta][this.convocatoria];
    },
    estado(): any {
      return this.estadoGlobal.estado || {};
    },
    cerrado(): any {
      return !!this.estadoGlobal.cerrado;
    },
  },
  methods: {
    async addVoto(partido: any) {
      if (this.cerrado) return;
      if (partido == 50) this.votoCruzado();
      else await this.alterVoto(partido, true);
    },

    votoCruzado() {
      const vca = this.votoCruzadoActual;

      // Inicializar los contadores y datos del voto cruzado

      vca.candidatosNI = {};
      vca.totalCandidatosNI = this.datosConvocatoriaActual.numero_representantes;
      vca.votosOtrosPartidos = {};
      vca.otrosPartidos = [];
      vca.candidatosNI = {};
      vca.numCandidatosNI = 0;
      vca.pestanya = 1;
      vca.numOtrosPartidos = 0;

      for (let n = 1; n <= this.votoCruzadoActual.totalCandidatosNI; n++) {
        vca.candidatosNI[n] = { idx: n, voted: false };
      }

      for (const n of this.partidos) {
        if (n.id == this.idNuevasIdeas || n.es_virtual) continue;
        vca.otrosPartidos.push(n);
        vca.votosOtrosPartidos[n.id] = 0;
      }

      // Mostrar el voto cruzado

      vca.visible = true;
    },

    cruzadoMarcarCandidatoNI(id: any) {
      // marcar/desmarcar
      const vca = this.votoCruzadoActual;
      const c = vca.candidatosNI[id];
      if (!c) return;

      if (c.voted) {
        vca.numCandidatosNI--;
        c.voted = false;
      } else {
        vca.numCandidatosNI++;
        c.voted = true;
      }
    },

    cruzadoAlterVoto(id: any, add: boolean) {
      // marcar/desmarcar
      const vca = this.votoCruzadoActual;

      if (!add && vca.votosOtrosPartidos[id] == 0) return;

      vca.votosOtrosPartidos[id] += add ? 1 : -1;
      vca.numOtrosPartidos+= add ? 1 : -1;
    },

    cruzadoAddVoto(id: any) {
      return this.cruzadoAlterVoto(id, true);
    },

    cruzadoDelVoto(id: any) {
      return this.cruzadoAlterVoto(id, false);
    },

    async cruzadoDescartar() {
      const _t = this as any;

      new Promise((accept) => {
        alertController
          .create({
            message: "¿Seguro que quiere descartar este voto preferencial?",
            buttons: [
              { text: "No", role: "cancel" },
              { text: "Sí", handler: accept },
            ],
          })
          .then((alert) => {
            alert.present();
          });
      }).then(function () {
        _t.votoCruzadoActual.visible = false;
      });
    },

    async cruzadoEnviar() {
      const _t = this as any;
      const vca = _t.votoCruzadoActual;

      if (vca.numCandidatosNI + vca.numOtrosPartidos == 0) {
        return new Promise((accept) => {
          alertController
            .create({
              message:
                "No se ha marcado ningún candidato o partido en el voto preferencial.",
              buttons: ["OK"],
            })
            .then((alert) => {
              alert.present();
              accept(true);
            });
        });
      }

      if (vca.numCandidatosNI + vca.numOtrosPartidos > _t.representantesVotoCruzado) {
        return new Promise((accept) => {
          alertController
            .create({
              message:
                "El número de marcas indicado (" + (vca.numCandidatosNI + vca.numOtrosPartidos) 
                + ") excede del número de representantes a elegir (" + _t.representantesVotoCruzado 
                + ").",
              buttons: ["OK"],
            })
            .then((alert) => {
              alert.present();
              accept(true);
            });
        });
      }


      // Construir el mensaje

      let votos = "";

      const datosVF = {} as any;

      if (vca.numCandidatosNI) {
        votos += _t.siglasNuevasIdeas + ": " +
          vca.numCandidatosNI +
          ' marca'+
          (vca.numCandidatosNI == 1 ? "" : "s") +
          " (nº" +
          (vca.numCandidatosNI == 1 ? "" : "s") +' ';

        const candReverse = [];
      
        for (let n = 1; n <= this.votoCruzadoActual.totalCandidatosNI; n++) {
          if (vca.candidatosNI[n].voted) {
            datosVF['vf_ni_' + n] = 1;
            candReverse.unshift(n);
          }
        }

        votos += candReverse.pop();

        while (candReverse.length) {
          votos += candReverse.length == 1 ? " y " : ", ";
          votos += candReverse.pop();
        }

        votos += ")<br/>";
      }

      for (const n of vca.otrosPartidos) {
        const nv = vca.votosOtrosPartidos[n.id];
        if (nv <= 0) continue;

        datosVF['vf_partido_' + n.id] = nv;


        votos += n.siglas + ": " + nv 
          + " marca"
          + (nv == 1 ? '' : 's')
          + "<br />";
      }

      new Promise((accept) => {
        alertController
          .create({
            message:
              "Va a confirmar el siguiente voto preferencial:<br /><br />" +
              votos +
              "<br />¿Es correcto?",
            buttons: [
              { text: "No", role: "cancel" },
              { text: "Sí", handler: accept },
            ],
          })
          .then((alert) => {
            alert.present();
          });
      }).then(function () {
        // TODO hacer el envío adecuado
        vca.visible = false;
        return _t.alterVoto(50, true, datosVF);
      });
    },

    async delVoto(partido: any) {
      await this.alterVoto(partido, false);
    },
    async alterVoto(partido: any, add: boolean, extra?: any) {
      if (typeof (this.votoActivo as any)[partido] === "undefined")
        (this.votoActivo as any)[partido] = 0;

      // Evitar doble clic (a mejorar)

      if ((this.votoActivo as any)[partido]) return;

      (this.votoActivo as any)[partido]++;

      // precargar el cambio

      const conteoDummy =  {
        /* eslint-disable @typescript-eslint/camelcase */
        id_junta: this.bootstrap.junta.id,
        id_convocatoria: this.convocatoria,
        id_partido: partido,
        ok: true,
        /* eslint-enable @typescript-eslint/camelcase */
        votos: this.estado[partido] + (add ? 1 : -1)
      };

      store.commit("storeVotoConteo",conteoDummy);

      const r = await cyanRequest(
        "agente/" +
          (add ? "addVotoCR" : "deleteVotoCR") +
          "/" +
          this.junta +
          "/" +
          this.convocatoria +
          "/" +
          partido,
        {
          needsAuth: true,
          isPost: true,
          params: extra || {},
          silent: false,
          isModal: false,
        }
      );

      (this.votoActivo as any)[partido]--;

      if (r.ok) {
        store.commit( r.isFull ? "storeConteo" : "storeVotoConteo", r);
      }
      else this.actualizarDatos();
    },

    detalleVF() {

      const nvf = this.estado['50'];
      const dca = this.datosConvocatoriaActual;
      let msg = '';

      if (nvf) {

        msg = 'Total de votos preferenciales emitidos: ' + nvf + '<br /><br />Fracciones por partido:<br /><br />';

        const evf = this.estadoGlobal.estado_vf || { vf: {}, marcas: {}};

        for (const n of dca.orden_partidos) {
          if (dca.mapa_partidos[n].es_virtual) continue;
          msg += dca.mapa_partidos[n].siglas + ': ' + (evf.vf[n] || 0) + '<br />';
        }

        msg += '<br />Detalle candidaturas ' + this.nombreNuevasIdeas + ':<br />';

        for (let n = 1; n <= this.representantesVotoCruzado; n++) {
          msg += '<br />Candidatura nº ' + n + ': ' + (evf.marcas[n] || 0);
        }

      } else {
        msg = 'No se han registrado votos preferenciales.'
      }

      new Promise(() => {
        alertController
          .create({
            message: msg, buttons: [ 'OK' ]
          })
          .then((alert) => {
            alert.present();
          });
      })


    },

    async cerrarCR() {
      const _t = this as any;
      const nombreConvocatoria = _t.bootstrap.convocatorias[this.convocatoria];

      new Promise((accept) => {
        alertController
          .create({
            message:
              "Atención: va a dar por cerrado el conteo rápido para " +
              nombreConvocatoria +
              ". ¿De acuerdo?",
            buttons: [
              { text: "No", role: "cancel" },
              { text: "Sí", handler: accept },
            ],
          })
          .then((alert) => {
            alert.present();
          });
      })
        .then(function () {
          return cyanRequest(
            "agente/cerrarCR/" + _t.junta + "/" + _t.convocatoria,
            {
              needsAuth: true,
              isPost: true,
              silent: false,
              isModal: true,
            }
          );
        })
        .then(function (r) {
          if (r.ok) {
            store.commit("storeConteo", r);
          } else {
            _t.actualizarDatos();
          }
          // TODO - traslado
        });
    },
    actualizarDatos() {
      const tieneDatos =
        store.state.conteo[this.junta] &&
        store.state.conteo[this.junta][this.convocatoria] &&
        store.state.conteo[this.junta][this.convocatoria].ok;

      cyanRequest(
        "agente/getEstadoCR/" + this.junta + "/" + this.convocatoria,
        {
          needsAuth: true,
          silent: tieneDatos,
          isModal: !tieneDatos,
        }
      ).then(function (r) {
        if (r.ok) {
          store.commit("storeConteo", r);
        }
      });
    },
  },
  mounted: function () {
    if (this.convocatoria !== "0") return;
    const k = Object.keys(this.convocatorias);
    if (k.length) this.convocatoria = k[0];
  },

  watch: {
    convocatorias(newVal) {
      if (newVal && this.convocatoria === "0") {
        this.convocatoria = Object.keys(newVal)[0];
      }
    },
    async convocatoria(newVal) {
      if (newVal == "0") return;
      this.actualizarDatos();
    },
  },
});
</script>

<style scoped>
.partido-top {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.partido-votos {
  font-size: 200%;
  padding: 10px;
}

.partido-oscuro {
  color: white;
}

.candidato-voto {
  color: white;
  background: #050;
}

.candidato-no-voto {
  background: white;
}

.partido-activo {
  opacity: 0.5;
}

.conteoRapidoCruzado {

  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;

  background: rgb(214, 239, 242);
}

.fade-enter {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.fade-leave {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}

.partido-outer {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

</style>